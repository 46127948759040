import { UserAgent, SessionState, Registerer } from "sip.js";

const audio = new Audio(require("../assets/ringing.wav"));

export const initWebPhone = function (auth, identity, onInvite) {
  console.log("Initializing Phone");
  console.log("Initializing indentiy: " + identity);
  const credentials = {
    aor: auth.aor,
    password: auth.password,
    displayName: identity,
  };
  //console.log(credentials);

  const transportOptions = {
    server: "wss://wss.maroc-integration.ma:4443",
  };

  const uri = UserAgent.makeURI(`sip:${credentials.aor}`);

  const userAgentOptions = {
    authorizationPassword: credentials.password,
    authorizationUsername: credentials.aor,
    displayName: credentials.displayName,
    transportOptions,
    delegate: {
      onInvite,
    },
    uri,
  };

  return userAgentOptions;
};

export const initUA = function (options) {
  return new UserAgent(options);
};

export const registerUA = function (ua) {
  return new Registerer(ua);
};

export const onInvite = (invitation) => {
  invitation.stateChange.addListener((state) => {
    console.log(`Session state changed to ${state}`);
    switch (state) {
      case SessionState.Initial:
        this.status = "CONNECTING ...";
        break;
      case SessionState.Establishing:
        setInterval(() => {
          this.audio.play();
          this.status = "RINGING ...";
        }, 4000);
        break;
      case SessionState.Established:
        this.audio.pause();
        this.setupRemoteMedia(invitation);
        break;
      case SessionState.Terminating:
      // fall through
      case SessionState.Terminated:
        this.audio.pause();
        this.cleanupMedia();
        break;
      default:
        throw new Error("Unknown session state.");
    }
  });
};

export const startRingTone = function () {
  return setInterval(() => {
    audio.play();
  }, 3000);
};

export const stopRingTone = function (ring) {
  clearInterval(ring);
  audio.pause();
};
